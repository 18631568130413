body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.rec.rec-arrow {
  background-color: #ffffff;
  color: #d94804;
  outline: none;  
}
.rec.rec-arrow-left:hover:enabled,
.rec.rec-arrow-right:hover:enabled{
  background-Color: #fc7c28;
  color: #ffffff;
  outline: none;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}
.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
  background-color:#fa8a566b !important;

}
.alice-carousel__dots
.alice-carousel__dots-item {
  background-color:#e95a07da !important;

}
.MuiSwitch-thumb {
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 50%;
  background-image: linear-gradient(
296deg, #d94804, #584e4a);
}
